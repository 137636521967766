import { css } from '@emotion/react'
import React, { Component } from "react"
import { CardElevation, Encuesta } from '@findep/mf-landings-core'
import { navigate } from "../../helpers/queryNavigate"
import { navigate as navigateGatsby } from "gatsby"

import { CataloguesService } from '../../services/LandingV4'
import { encuestaBFF } from '../../lib/saveData'
import { withThemeProps } from '@findep/microfronts-core';
import PropTypes from 'prop-types'

import { Grid, Container } from "@material-ui/core"
class EncuestaView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idPregunta: 0,
            idEncuesta: 0,
            pregunta: '',
        }
        console.log(this.props.pageContext)
        this.eventosalir = this.eventosalir.bind(this)
        this.eventoenvia = this.eventoenvia.bind(this)
    }

    eventoenvia = async (respuesta) => {
        await encuestaBFF(this.state, respuesta)
        this.props.pageContext.next.path === '/' ? navigateGatsby(this.props.pageContext.next.path) : navigate(this.props.pageContext.next.path)
        sessionStorage.setItem('survey', 'true')
    }

    eventosalir = () => {
        this.props.pageContext.alternatePath === "/" ? navigateGatsby(this.props.pageContext.alternatePath) : navigate(this.props.pageContext.alternatePath)
    }

    async componentDidMount() {        
        let lang = this.props.pageContext?.locale === "enUS" ? "en-US" : "es-MX"

        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId
        const catalogos = new CataloguesService()
        const catData = await catalogos.getSurveys(id, lang)
        const { idPregunta, idEncuesta, pregunta } = catData.data[0]
        this.setState({ idPregunta })
        this.setState({ idEncuesta })
        this.setState({ pregunta })
    }

    componentDidUpdate(prevProp, prevState) {

    }


    render() {        
        const { pregunta } = this.state
        const textHeader = css`        
        text-align: center
        `

        const encuesta = {
            botonuno: this.props.botonuno,
            botondos: this.props.botondos,
            referenciauno: this.props.referenciauno,
            referenciados: this.props.referenciados,
            leyenda: pregunta
        }
        return (
            <Container justify="center" >
                <Grid container justify="center">
                    <h2 css={textHeader}>{this.props.TitleEncuesta}</h2>
                    <CardElevation noPadding>
                        <Encuesta
                            idBtnCero="encuesta"
                            idBtnOne="encuesta"
                            idBtnTwo="encuesta"
                            idBtnThree="encuesta"
                            idBtnFour="encuesta"
                            idBtnFive="encuesta"
                            idBtnSix="encuesta"
                            idBtnSeven="encuesta"
                            idBtnHeight="encuesta"
                            idBtnNine="encuesta"
                            idBtnSalir="encuesta-salir"
                            idBtnEnviar="encuesta-enviar"
                            eventoenvia={this.eventoenvia}
                            eventosalir={this.eventosalir}
                            textpoll={encuesta}
                        />
                    </CardElevation>
                </Grid>
            </Container>
        )
    }
}


export default withThemeProps(EncuestaView, 'VDPNEncuesta')

EncuestaView.propTypes = {
    botonuno: PropTypes.string,
    botondos: PropTypes.string,
    referenciauno: PropTypes.string,
    referenciados: PropTypes.string,
    leyenda: PropTypes.string,
}

EncuestaView.defaultProps = {
    botonuno: 'SALIR SIN ENVIAR',
    botondos: 'ENVIAR',
    referenciauno: 'Nada probable',
    referenciados: 'Muy probable',
    leyenda: "",
    TitleEncuesta: 'Encuesta de Satisfacción'
}