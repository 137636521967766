import { ScoreService,Encuesta, Firma, VerificacionTelefonica } from '../services/LandingV4'
import { navigate } from "../helpers/queryNavigate"

export const scoreBFF = async (_this, state, isPensionados = false) => {
    let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    const sc = new ScoreService(flujo.creditApplicationId)
    const { civilselect, jobselect, entryselect, monthlyincome, expensestext, dependentsselect, timehome, timejob } = state.data
    const { phonejob, phonealternative } = state
    sc.setMaritalStatusId(civilselect)
    sc.setIncome(parseInt(monthlyincome))
    sc.setExpenses(parseInt(expensestext))
    sc.setHomeSinceTotalMonths(parseInt(timehome))
    sc.setDependents(dependentsselect)

    if(!isPensionados) {
        sc.setIncomeSourceId(entryselect)
        sc.setOccupationId(jobselect)
        sc.setJobSinceTotalMonths(parseInt(timejob))
        sc.setWorkPhoneNumber(phonejob)
        sc.setAlternatePhoneNumber(phonealternative)
    }

    let response

    try {

        if(isPensionados) {
            response = await sc.sendPensionados()
        } else {
            response = await sc.send()
        }

        console.log("This is response.data saveDataaaaa", response.data);
        let aprobado = response.data.preapproved
        const isDigital = response.data.isDigital
        flujo = {
            ...flujo,
            datosAdicionales: response.data
        }

        sessionStorage.setItem('flujo', JSON.stringify(flujo))
        if (!aprobado) {
            navigate(_this.props.pageContext?.errorPath)
        } else {
            isDigital
                ? navigate(_this.props.pageContext?.next?.path)
                : navigate(_this.props.pageContext?.alternatePath)
        }
        _this.setState({ loading: false })

    } catch(error) {
        _this.setState({ loading: false })
        console.log("error", error);
        _this.setState({ errorEndPoint: true })
    }

}

export const encuestaBFF = (state, respuesta) => {

    let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    const en = new Encuesta(id)
    en.setIdEncuesta(parseInt(state.idEncuesta))
    en.setIdPregunta(parseInt(state.idPregunta))
    en.setRespuesta(respuesta)

    return en.send()
        .then((response) => {
            console.log("encuestaBFF test...");
            response.status === 204 && console.log("Encuesta contestada correctamente")
        })
        .catch(error => {
            console.log('error', error);
            console.log("Ocurrio un error, intente mas tarde!")
        })
}

export const reSendSignatureBFF = (_this) => {
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId
    const firma = new Firma(id)
    return firma.reSendSignature(id)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                _this.setState({ loading: false })
                _this.setState({ errorService: false })
                _this.handleStartStop()
            } else {
                _this.setState({ errorService: false })
                _this.setState({ loading: false })
                _this.setState({ errorService: true })
                _this.setState({ mensaje: 'Ocurrio un error al enviar el código, intente mas tarde!' })
            }

        })
        .catch(() => {
            _this.setState({ count: 0 })
            _this.setState({ timeout: true })
            _this.setState({ loading: false })
            _this.setState({ errorService: true })
            _this.setState({ mensaje: 'Ocurrio un error al enviar el código, intente mas tarde!' })
        })
}


export const verificationsPhoneV2 = async (code, id, _this) => {
    const firma = new Firma(id)
    firma.setCode(code)

    try {
        await firma.verificationsPhone()
            .then((response) => {
                const { data } = response
                if (response.status === 200) {
                    if (data.valid) {
                        _this.setState({
                            mensaje: "Códigos correctos puedes continuar",
                            loading: false,
                            codesequals: true
                        })
                    } else {
                        _this.setState({
                            errorService: true,
                            mensaje: "Códigos incorrectos",
                            loading: false,
                            codesequals: false
                        })
                    }
                }
            })
    } catch (error) {
        _this.setState({
            errorService: true,
            mensaje: "Lo sentimos, por el momento el servicio no se encuentra disponible",
            loading: false,
            codesequals: false
        })
    }
}

export const verificationsPhone = async (code, id, _this) => {
    const firma = new Firma(id)
    firma.setCode(code)

    try {
        await firma.verificationsPhone()
            .then((response) => {
                const { data } = response
                if (response.status === 200) {
                    if (data.valid && data.canContinue) {
                        _this.setState({
                            success: true,
                            mensaje: "Códigos correctos puedes continuar",
                            loading: false,
                            switchButton: true
                        })
                    } else {
                        if(!data.canContinue){
                            navigate(_this.props.pageContext?.errorPath?.path)
                        }else{
                            _this.setState({
                                switchButton: false,
                                errorService: true,
                                mensaje: "Códigos incorrectos",
                                loading: false
                            })
                        }
                    }
                }
            })
    } catch (error) {
        _this.setState({
            switchButton: false,
            errorService: true,
            mensaje: "Lo sentimos, por el momento el servicio no se encuentra disponible",
            loading: false
        })
    }
}

export const signatureV2 = async (code, confirmation, id, _this) => {
    const firma = new Firma(id)
    firma.setCode(code)
    firma.setConfirmation(confirmation)

    try {
        const { data } = await firma.signatureV2()
        if (data.valid) {
            _this.setState({ switchButton: true })
            navigate(_this.props.pageContext?.next?.path)
            _this.setState({ loading: false })
        } else {
            _this.setState({ switchButton: false })
            _this.setState({ loading: false })
        }
    } catch (error) {
        _this.setState({
            openBackDrop: false,
            errorService: true,
            mensaje: "Lo sentimos, por el momento el servicio no se encuentra disponible",
            loading: false
        })
        _this.setState({ switchButton: false })
    }
}


export const PrescoreNip = async (id, _this) => {
    const firma = new Firma(id)
    try {
        await firma.prescoreNip()
            .then((response) => {
                const { data } = response
                if (response.status === 200) {
                    if (data.approved) {
                        sessionStorage.setItem('bcScore', data.bcScore)
                        navigate(_this.props.pageContext?.next?.path)
                        _this.setState({ loading: false })
                    } else {
                        navigate(_this.props.pageContext?.errorPath)
                        _this.setState({ loading: false })
                    }
                }
                _this.setState({ loading: false })
            })
    } catch (error) {
        _this.setState({
            openBackDrop: false,
            errorService: true,
            mensaje: "Lo sentimos, por el momento el servicio no se encuentra disponible",
            loading: false
        })
    }
}

export const verifySignatureBFF = (code, _this) => {
    // console.log("_this",_this.props.pageContext.next.path)
    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    _this.setState({ creditApplicationId: id })

    const firma = new Firma(id)
    firma.setCode(code)

    return firma.verifySignature()
        .then((response) => {
            if (response.data.valid) {
                _this.setState({ loading: false })
                const codeReceive = response.data.valid
                codeReceive ? _this.setState({ timeout: false }) : _this.setState({ timeout: true })
                codeReceive ? _this.setState({ correctCode: true }) : _this.setState({ correctCode: false })
                _this.setState({ continuar: true })
                _this.setState({ success: true })
                _this.setState({ mensaje: 'Código correcto, puedes continuar' })

                if(_this.props.pageContext.template==="verificacion-telefonica.js"){
                    navigate(_this.props.pageContext.next.path)
                }
            } else {
                _this.setState({ loading: false })
                _this.setState({ errorService: true })
                _this.setState({ mensaje: 'Código incorrecto, verifica tu código ó reintenta el envio del código.' })
            }
        })
        .catch(error => {
            _this.setState({ loading: false })
            _this.setState({ errorService: true })
            _this.setState({ mensaje: 'Lo sentimos, por el momento el servicio no se encuentra disponible' })
            console.log('error', error);
            console.log("Ocurrio un error, intente mas tarde!")
        })

}


export const verifySignatureBFFVT = (code, _this) => {
    const firma = new Firma(_this.state.creditApplicationId)
    firma.setCode(parseInt(code))
    return firma.verifySignature()
        .then((response) => {
            if (response.data.valid) {
                const codeReceive = response.data.valid
                codeReceive ? _this.setState({ timeout: false }) : _this.setState({ timeout: true })
                codeReceive ? _this.setState({ continuar: true }) : _this.setState({ continuar: false })
                codeReceive ? _this.setState({ correctCode: true }) : _this.setState({ correctCode: false })
            } else {
                _this.setState({ errorService: true })
                _this.setState({ mensaje: "Código incorrecto vuelve a intentarlo" })
            }
        })
        .catch(error => {
            _this.setState({ errorService: true })
            _this.setState({ mensaje: "Ocurrio un error, intente mas tarde!" })
            return true
        })
}

export const correctionsMobileNumber = (mobilePhone, _this) => {
    const vertel = new VerificacionTelefonica(_this.state.creditApplicationId)
    vertel.setMobileNumber(mobilePhone)
    return vertel.correctionsMobile()
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                _this.setState({ timeout: true })
            } else {
                _this.setState({ loading: false })
                _this.setState({ timeout: false })
                _this.setState({ errorService: true })
                _this.setState({ mensaje: "Ocurrio un error, intente mas tarde!" })
            }
        })
        .catch(() => {
            _this.setState({ loading: false })
            _this.setState({ timeout: false })
            _this.setState({ errorService: true })
            _this.setState({ telefono: '' })
            _this.setState({ mensaje: "Ocurrio un error, intente mas tarde!" })
            return true
        })
}

export const inhabilitaResend = (_this) => {
    _this.setState({ timeout: false })
    setTimeout(() => {
        // timeoutFunction(_this)
        _this.setState({
            timeout: true
        });
    }, parseInt(_this.state.secondsToWait * 1000));

}

export const habilitaResend = (_this) => {
    _this.setState({ timeout: true })
}

export const prescoreNipResend = (id, _this) => {
    const firma = new Firma(id)
    return firma.prescoreNipResend(id)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                _this.setState({ loading: false })
            } else {
                _this.setState({ loading: false })
                _this.setState({ errorService: true })
                _this.setState({ mensaje: "Ocurrio un error, intente mas tarde!" })
            }
            _this.setState({ count: _this.state.secondsToWait })

        })
        .catch(error => {
            _this.setState({
                openBackDrop: false,
                errorService: true,
                mensaje: "Lo sentimos, por el momento el servicio no se encuentra disponible",
                loading: false,
                timeout: true,
            })
            console.log('error', error);
            console.log("Ocurrio un error, intente mas tarde!")
        })
}

export const abort = (_this) => {
    const flujo = sessionStorage.getItem('flujo')
    let idFlujo = JSON.parse(flujo)
    const firma = new Firma(idFlujo.creditApplicationId)
    return firma.abort(idFlujo.creditApplicationId)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                _this.setState({ loading: false })
                navigate(_this.props.pageContext.alternatePath)
            } else {
                _this.setState({ loading: false })
                _this.setState({ errorService: true })
                _this.setState({ mensaje: "Ocurrio un error, intente mas tarde!" })
            }

        })
        .catch(error => {
            _this.setState({ loading: false })
            console.log('error', error);
            console.log("Ocurrio un error, intente mas tarde!")
        })
}