import React from "react"
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import Encuesta from '../components/pages/Encuesta'

const IndexPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    pageContext={pageContext}
    location={location}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <Encuesta pageContext={pageContext} />
      </>
    )}
  />
)

export default IndexPage
